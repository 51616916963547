import { Component, HostListener } from '@angular/core';
import { Globals } from './globals';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser'; 
import { filter } from 'rxjs/operators';
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(public globals: Globals, private router: Router,  
    private activatedRoute: ActivatedRoute,  
    private titleService: Title) { }

    // @HostListener('window:popstate', ['$event'])
    // onPopState(event: Event): void {
    //   this.router.navigateByUrl(`${window.location.pathname}`, {
    //     onSameUrlNavigation: 'reload',
    //     skipLocationChange: true,
    //   });
    // }
  

  ngOnInit(): void { 
    const body = document.querySelector('body');
    body.style.setProperty('--screen-height', $(window).height() + "px");

    this.router.events.pipe(  
      filter(event => event instanceof NavigationEnd),  
    ).subscribe(() => {  
      const rt = this.getChild(this.activatedRoute);  
      rt.data.subscribe(data => { 
        this.titleService.setTitle('Products | '+data.title)});  
    }); 
  }

  getChild(activatedRoute: ActivatedRoute) {  
    if (activatedRoute.firstChild) {  
      return this.getChild(activatedRoute.firstChild);  
    } else {  
      return activatedRoute;  
    }  
  }  

}

