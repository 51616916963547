import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Globals } from '../../globals';
import { LabelSettings, LabelFn } from '@progress/kendo-angular-progressbar';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { AssessmentServiceService } from 'src/app/services/assessment-service.service'; 
import { ConfigurationService } from '../../services/configuration.service';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { SortDescriptor, process } from '@progress/kendo-data-query';
//import { Label } from '@amcharts/amcharts4/core';
declare var $, introJs, Swal: any;

@Component({
  selector: 'app-assessment',
  templateUrl: './assessment.component.html',
  styleUrl: './assessment.component.css'
})
export class AssessmentComponent implements OnInit {
  renewAssesmentId:any;


  @ViewChild('mainBlock', { static: false }) mainBlock!: ElementRef;

  public surveyQuestionOptions: Array<string> = ['General Medical', 'General Surgical', 'Cardiovascular', 'Respiratory', 'Gastrointestinal', 'Orthopedic', 'Neurological', 'Oncology', 'Infection Control', 'Long-Term Acute Care'];
  public value = 0;
  public max = 0;
  public dmnlabel: LabelSettings = {};
  public domainFormattedLabels = [];
  public percentage: any = 0;
  IsGridView: boolean = true;
  public formatter: LabelFn = (value: number): string => {

    // 
    return `${value}/${this.assessmentDetails.no_of_question} Completed (${this.assessmentDetails.progress}%)`;
    // return `${value}/${this.assessmentDetails.no_of_question} Assessment Completed (${this.assessmentDetails.progress}%)`;
    // return `Your overall progress: ${this.assessmentDetails.progress}%`;
  }

  public opened = false;

  public close(status: string): void {
    this.opened = false;
    this.renderer.removeClass(document.body, 'k-overflow-hidden');
  }

  public open(): void {
    this.opened = true;
    this.renderer.addClass(document.body, 'k-overflow-hidden');
  }

  createLabelFormatter(no_of_question: number, progress: number): LabelSettings {
    return {
      format: (value: number): string => {
        return `${value}/${no_of_question} Completed (${progress}%)`;
      },
      position: 'center'
    }
  }


  public label: LabelSettings = {
    format: this.formatter,
    position: 'center'
  };

  public label1: LabelSettings = {
    visible: true,
    format: 'percent',
    position: 'center'
  };

  domain_block: boolean = true;
  subdomain_block: boolean = false;
  item_block: boolean = false;
  assessmentData: any = [];
  rowAssessmentData: any;
  currentDomain: any = { 'subdomain': [] };
  domain_index = 0;
  domainData: any;
  assessmentDetails: any = { 'id': null, 'attempt_question': 0, 'no_of_question': 0, 'progress': 0, 'valid': false };
  ratingList: any;
  is_purchased: any;
  ratingEntity: any = [];
  HideCompleted = false;
  SurveyQuestions: any = [];
  isEditAssesment: any = false;

  constructor(public globals: Globals, private router: Router, private route: ActivatedRoute, private formBuilder: UntypedFormBuilder, private AssessmentService: AssessmentServiceService, private ConfigurationService: ConfigurationService, private renderer: Renderer2) { }

  ngOnInit(): void {

    var currentUrl = this.router.url;

    if (currentUrl.includes('/assessment/edit-assessment/')) {
      this.isEditAssesment = true;
    }
    else {
      this.isEditAssesment = false;
    }


    this.dmnlabel = {
      format: this.formatter,
      position: "center",
    };

    this.assessmentDetails.id = this.route.snapshot.paramMap.get('id');
    $("#domain_block").hide();
    $("#subdomain_block").hide();
    $("#item_block").hide();
    $("#help_btn").hide();
    this.getConfigData();
    this.getData();

    // this.getLocalData();
    $('.rate_stars input[type="radio"]').change(function (e) {
      $('.rating_input').val(this.value);
    });

    // introJs().setOptions({
    //   exitOnOverlayClick: false,
    //   exitOnEsc: false,
    //   doneLabel: 'Close',
    //   showBullets: false,
    //   showProgress: true
    // }
    // ).start();
    if (screen.width < 991) {
      $('.mobile_subdomain').attr('data-title', 'Subdomains');
    //   $('.mobile_subdomain').attr('data-intro', 'This displays your overall progress for each of the subdomains within this domain.');
    }
    else {
      $('#thumbs-block').attr('data-title', 'Subdomains');
    //   $('#thumbs-block').attr('data-intro', 'This displays your overall progress for each of the subdomains within this domain.');
    }

  }

  help(currentItem) {
    if (currentItem == null) {
      currentItem = parseInt($('#thumbs-block').find('.synced > div:first-child').attr('id'));
    }
    $('.subdomain_items').removeAttr('data-title');
    $('.subdomain_items').removeAttr('data-intro',);
    let id = '#item_' + currentItem + '_' + 0;
    $(id).attr('data-title', 'Knowledge Statements');
    // $(id).attr('data-intro', 'For each knowledge statement, a 5-point proficiency rating scale is provided. Please use the scale to honestly rate each knowledge statement.');

    $('.domain_items').removeAttr('data-title');
    $('.domain_items').removeAttr('data-intro',);
    let id1 = '#assdomain_0';
    $(id1).attr('data-title', 'Domains');
    // $(id1).attr('data-intro', 'This demonstrates your progress to completion of the domain.');

    $('.rating_item').removeAttr('data-title');
    $('.rating_item').removeAttr('data-intro',);


    //alert(currentItem);
    //currentItem = currentItem + 1;
    // alert(currentItem);
    for(var i=0;i<=this.ratingList.length;i++){
 
      let id2 = '#rating' + currentItem + '_' + i;
      //  alert(id2);
    
        $(id2).attr('data-title', this.ratingList[i]?.display_text);
        $(id2).attr('data-intro', this.ratingList[i]?.description);
    }
  //   let id2 = '#rating' + currentItem + '_' + 0;
  // //  alert(id2);

  //   $(id2).attr('data-title', 'Rating1');
  //   $(id2).attr('data-intro', 'description1');

    setTimeout(() => {
      var sync1 = $("#main-block");
      var current = currentItem;
      $("#thumbs-block")
        .find(".owl-item")[0]
        .removeClass("synced")
        .eq(current)
        .addClass("synced");
      sync1.trigger("owl.goTo", currentItem);
    }, 100);
    introJs().setOptions({
      exitOnOverlayClick: false,
      exitOnEsc: false,
      doneLabel: 'Close',
      showBullets: false,
      showProgress: true
    }
    ).start();
  }

  getData() {
    this.AssessmentService.getQuestions()
      .then((data: any) => {
  
        this.rowAssessmentData = data['data'];
        this.is_purchased = data.message.is_purchased;
        this.globals.isLoading = false;
        this.rowAssessmentData.domain = this.rowAssessmentData.domain;
        this.rowAssessmentData.domain.forEach((d_element, d_index, d_arr) => {
          d_element.subdomain = d_element.subdomain;
        });
        this.prepareData();
      },
        (error) => {
          this.globals.isLoading = false;
          this.router.navigate(['/assessment/my-assessments']);
        });

  }


  backToSubscription() {
    this.router.navigate(['/assessment/my-assessments']);


  }

  backToMain() {

      window.location.href =this.globals.dcmUrl +(['/assessment/purchase-subscription/',0]);

  }

  checkDomainSubdomainMatch(data: any) {
    const domains = data.domain;
    const questions = data.questions;

    const report: string[] = [];

    // Check if subdomains in domains array are present in questions
    domains.forEach((domain: any) => {
      const domainName = domain.name;

      if (!questions[domainName]) {
        report.push(`Domain "${domainName}" in domain array but missing in questions array.`);
      } else {
        const domainQuestions = questions[domainName];
        const subdomainNamesInQuestions = Object.keys(domainQuestions);

        domain.subdomain.forEach((subdomain: any) => {
          const subdomainName = subdomain.name;

          if (!domainQuestions[subdomainName]) {
            report.push(`Subdomain "${subdomainName}" under domain "${domainName}" is in domain array but missing in questions.`);
          }
        });

        // Check for subdomains in questions that are missing in domain array
        domain.subdomain.forEach((subdomain: any) => {
          const subdomainName = subdomain.name;

          if (!subdomainNamesInQuestions.includes(subdomainName)) {
            report.push(`Subdomain "${subdomainName}" exists in questions but not in domains.`);
          }
        });
      }
    });

    // Check if any domain in questions is missing in domain array
    Object.keys(questions).forEach((domainName: string) => {
      const foundDomain = domains.some((domain: any) => domain.name === domainName);

      if (!foundDomain) {
        report.push(`Domain "${domainName}" in questions array but missing in domains array.`);
      }
    });

    // Check for subdomains in questions that are missing in domain subdomain array
    Object.keys(questions).forEach((domainName: string) => {
      const domainQuestions = questions[domainName];
      const domain = domains.find((d: any) => d.name === domainName);

      if (domain) {
        const subdomainNamesInDomain = domain.subdomain.map((subdomain: any) => subdomain.name);
        Object.keys(domainQuestions).forEach((subdomainName: string) => {
          if (!subdomainNamesInDomain.includes(subdomainName)) {
            report.push(`Subdomain "${subdomainName}" exists in questions under domain "${domainName}" but not in domain subdomain array.`);
          }
        });
      }
    });

    if (report.length === 0) {
    } else {
      report.forEach((issue: string) => console.error(issue));
    }
  }

  getConfigData() {
    this.globals.isLoading = true;
    this.ConfigurationService.getAll({ "key": ["Rating"], "status": "1" })
      .then((data: any) => {
        this.ratingList = data.sort(this.sortRating);
      },
        (error) => {
          this.globals.isLoading = false;
        });
  }

  prepareData() {
    this.assessmentDetails.assign_date = this.rowAssessmentData.assign_date;
    this.assessmentDetails.no_of_question = 0;
    this.assessmentDetails.attempt_question = 0;
    this.assessmentDetails.progress = 0;
    this.domainData = this.rowAssessmentData.domain;

    Object.entries(this.rowAssessmentData.questions).forEach((d_element, d_index, d_arr) => {
      var domain = this.domainData[d_index];
      domain.no_of_question = 0;
      domain.attempt_question = 0;
      domain.progress = 0;
      Object.entries(d_element[1]).forEach((sd_element, sd_index, sd_arr) => {
        if(domain.subdomain[sd_index]!==undefined) {
          domain.subdomain[sd_index].questions = sd_element[1];

          domain.subdomain[sd_index].mandatory_question = domain.subdomain[sd_index].questions[0].mandatory_question;
        }
        // else{

        // }

      });
      if (domain.subdomain.length > 0) {
        this.assessmentData.push(domain);

      }
    });
    this.checkProgress();
    this.getDomain();
  }

  getDomain() {
    $("#domain_block").show();
    $("#subdomain_block").hide();
    $("#item_block").hide();
    this.domain_block = true;
    this.subdomain_block = false;
    this.item_block = false;
    $("#help_btn").hide();
    this.currentDomain = { 'subdomain': [] };

    // domain loop
    this.assessmentData.forEach((d_element, d_index, d_arr) => {
      setTimeout(() => {
        $('#Domain' + d_index).circleProgress({
          value: d_element.progress / 100,
          size: 50.0,
          emptyFill: '#ccc',
          fill: { gradient: ['#6e61aa', '#209cb7'] }
        }).on('circle-animation-progress', function (event) {
          $(this).find('strong').html('<span>' + d_element.progress + '%</span>');
        });
      }, 200);
    });
    $('.owl.carousel').trigger('destroy.owl.carousel');
    $('#main-block').data('owlCarousel')?.destroy();
    $('#thumbs-block').data('owlCarousel')?.destroy();
  }

  domainChangeFormSubd(domain, index) {
    this.domain_index = index;
    this.currentDomain = domain;
    if (this.HideCompleted && this.currentDomain.progress < 100) {
      this.currentDomain.subdomain1 = this.currentDomain.subdomain.filter(
        s => s.progress < 100
      );
    } else {
      this.currentDomain.subdomain1 = this.currentDomain.subdomain;
    }
  }

  domainChangeFormBtn(index) {

    this.domain_index = index;

    this.currentDomain = this.assessmentData[index];
    if (this.HideCompleted && this.currentDomain.progress < 100) {
      this.currentDomain.subdomain1 = this.currentDomain.subdomain.filter(
        s => s.progress < 100
      );
    } else {
      this.currentDomain.subdomain1 = this.currentDomain.subdomain;
    }
    $('.domainc').removeClass('active');
    $('#assdomain' + this.domain_index + '-tab').addClass('active');
    this.currentDomain.subdomain1.forEach((sd_element, sd_index, sd_arr) => {
      setTimeout(() => {
        $('#SubDomainCard' + sd_index).circleProgress({
          value: sd_element.progress / 100,
          size: 50.0,
          emptyFill: '#ccc',
          fill: { gradient: ['#6e61aa', '#209cb7'] }
        }).on('circle-animation-progress', function (event) {
          $(this).find('strong').html('<span>' + sd_element.progress + '%</span>');
        });
        $('#SubDomainCardMobile' + sd_index).circleProgress({
          value: sd_element.progress / 100,
          size: 50.0,
          emptyFill: '#ccc',
          fill: { gradient: ['#6e61aa', '#209cb7'] }
        }).on('circle-animation-progress', function (event) {
          $(this).find('strong').html('<span>' + sd_element.progress + '%</span>');
        });
        $('#SubDomain' + sd_index).circleProgress({
          value: sd_element.progress / 100,
          size: 50.0,
          emptyFill: '#ccc',
          fill: { gradient: ['#6e61aa', '#209cb7'] }
        }).on('circle-animation-progress', function (event) {
          $(this).find('strong').html('<span>' + sd_element.progress + '%</span>');
        });
      }, 200);
    });
    $('.owl.carousel').trigger('destroy.owl.carousel');
    $('#main-block').data('owlCarousel').destroy();
    $('#thumbs-block').data('owlCarousel').destroy();
    this.loadSlider(0);
  }

  domainChangeFromItem(domain, index) {
    this.domain_index = index;
    this.currentDomain = domain;
    if (this.HideCompleted && this.currentDomain.progress < 100) {
      this.currentDomain.subdomain1 = this.currentDomain.subdomain.filter(
        s => s.progress < 100
      );
    } else {
      this.currentDomain.subdomain1 = this.currentDomain.subdomain;
    }
    this.currentDomain.subdomain1.forEach((sd_element, sd_index, sd_arr) => {
      this.globals.isLoading = true;
      setTimeout(() => {
        $('#SubDomainCard' + sd_index).circleProgress({
          value: sd_element.progress / 100,
          size: 50.0,
          emptyFill: '#ccc',
          fill: { gradient: ['#6e61aa', '#209cb7'] }
        }).on('circle-animation-progress', function (event) {
          $(this).find('strong').html('<span>' + sd_element.progress + '%</span>');
        });
        $('#SubDomainCardMobile' + sd_index).circleProgress({
          value: sd_element.progress / 100,
          size: 50.0,
          emptyFill: '#ccc',
          fill: { gradient: ['#6e61aa', '#209cb7'] }
        }).on('circle-animation-progress', function (event) {
          $(this).find('strong').html('<span>' + sd_element.progress + '%</span>');
        });
        $('#SubDomain' + sd_index).circleProgress({
          value: sd_element.progress / 100,
          size: 50.0,
          emptyFill: '#ccc',
          fill: { gradient: ['#6e61aa', '#209cb7'] }
        }).on('circle-animation-progress', function (event) {
          $(this).find('strong').html('<span>' + sd_element.progress + '%</span>');
        });
      }, 200);
    });

    $('.owl.carousel').trigger('destroy.owl.carousel');
    $('#main-block').data('owlCarousel').destroy();
    $('#thumbs-block').data('owlCarousel').destroy();
    this.loadSlider(0);
  }

  getSubDomain(domain, index) {
    $("#domain_block").hide();
    $("#subdomain_block").show();
    $("#item_block").hide();
    this.domain_block = false;
    this.subdomain_block = true;
    this.item_block = false;
    $("#help_btn").hide();
    this.currentDomain = domain;
    if (this.HideCompleted && this.currentDomain.progress < 100) {
      this.currentDomain.subdomain1 = this.currentDomain.subdomain.filter(
        s => s.progress < 100
      );
    } else {
      this.currentDomain.subdomain1 = this.currentDomain.subdomain;
    }

    this.domain_index = index;
    $('.domainc').removeClass('active');
    $('#domain' + this.domain_index + '-tab').addClass('active');
    for (let i = 0; i < this.assessmentData.length; i++) {
      setTimeout(() => {
        var progress = this.assessmentData[i].progress;
        $('#domain_circle' + i).circleProgress({
          value: progress / 100,
          size: 50.0,
          emptyFill: '#ccc',
          fill: { gradient: ['#6e61aa', '#209cb7'] }
        }).on('circle-animation-progress', function (event) {
          $(this).find('strong').html('<span>' + progress + '%</span>');
        });
        $('#domain_circle_' + i).circleProgress({
          value: progress / 100,
          size: 50.0,
          emptyFill: '#ccc',
          fill: { gradient: ['#6e61aa', '#209cb7'] }
        }).on('circle-animation-progress', function (event) {
          $(this).find('strong').html('<span>' + progress + '%</span>');
        });
      }, 100);
    }
    setTimeout(() => {
      var progress = this.currentDomain.progress;
      $('#current_domain_circle').circleProgress({
        value: progress / 100,
        size: 50.0,
        emptyFill: '#ccc',
        fill: { gradient: ['#6e61aa', '#209cb7'] }
      }).on('circle-animation-progress', function (event) {
        $(this).find('strong').html('<span>' + progress + '%</span>');
      });
    }, 200);
    this.currentDomain.subdomain1.forEach((sd_element, sd_index, sd_arr) => {
      setTimeout(() => {
        $('#SubDomainCard' + sd_index).circleProgress({
          value: sd_element.progress / 100,
          size: 50.0,
          emptyFill: '#ccc',
          fill: { gradient: ['#6e61aa', '#209cb7'] }
        }).on('circle-animation-progress', function (event) {
          $(this).find('strong').html('<span>' + sd_element.progress + '%</span>');
        });
        $('#SubDomainCardMobile' + sd_index).circleProgress({
          value: sd_element.progress / 100,
          size: 50.0,
          emptyFill: '#ccc',
          fill: { gradient: ['#6e61aa', '#209cb7'] }
        }).on('circle-animation-progress', function (event) {
          $(this).find('strong').html('<span>' + sd_element.progress + '%</span>');
        });
        $('#SubDomain' + sd_index).circleProgress({
          value: sd_element.progress / 100,
          size: 50.0,
          emptyFill: '#ccc',
          fill: { gradient: ['#6e61aa', '#209cb7'] }
        }).on('circle-animation-progress', function (event) {
          $(this).find('strong').html('<span>' + sd_element.progress + '%</span>');
        });
      }, 200);
    });

    $('.owl.carousel').trigger('destroy.owl.carousel');
    $('#main-block').data('owlCarousel')?.destroy();
    $('#thumbs-block').data('owlCarousel')?.destroy();


  }

  getItems(currentItem) {
    $("#domain_block").hide();
    $("#subdomain_block").hide();
    $("#item_block").show();
    this.domain_block = false;
    this.subdomain_block = false;
    this.item_block = true;
    $("#help_btn").show();
    //if(index!=null){
    $('.domainc').removeClass('active');
    $('#assdomain' + this.domain_index + '-tab').addClass('active');
    //}   
    // $('.owl.carousel').trigger('destroy.owl.carousel');
    // $('#main-block').data('owlCarousel').destroy();
    // $('#thumbs-block').data('owlCarousel').destroy();

    if (this.HideCompleted && this.currentDomain.progress < 100) {
      this.currentDomain.subdomain1 = this.currentDomain.subdomain.filter(
        s => s.progress < 100
      );
    } else {
      this.currentDomain.subdomain1 = this.currentDomain.subdomain;
    }
    this.currentDomain.subdomain1.forEach((sd_element, sd_index, sd_arr) => {
      setTimeout(() => {
        $('#SubDomainCard' + sd_index).circleProgress({
          value: sd_element.progress / 100,
          size: 50.0,
          emptyFill: '#ccc',
          fill: { gradient: ['#6e61aa', '#209cb7'] }
        }).on('circle-animation-progress', function (event) {
          $(this).find('strong').html('<span>' + sd_element.progress + '%</span>');
        });
        $('#SubDomainCardMobile' + sd_index).circleProgress({
          value: sd_element.progress / 100,
          size: 50.0,
          emptyFill: '#ccc',
          fill: { gradient: ['#6e61aa', '#209cb7'] }
        }).on('circle-animation-progress', function (event) {
          $(this).find('strong').html('<span>' + sd_element.progress + '%</span>');
        });
        $('#SubDomain' + sd_index).circleProgress({
          value: sd_element.progress / 100,
          size: 50.0,
          emptyFill: '#ccc',
          fill: { gradient: ['#6e61aa', '#209cb7'] }
        }).on('circle-animation-progress', function (event) {
          $(this).find('strong').html('<span>' + sd_element.progress + '%</span>');
        });
      }, 200);
    });
    this.loadSlider(currentItem);
    if (this.assessmentDetails.attempt_question == 0) {
      this.help(currentItem);
    }
    let self = this;
    setTimeout(() => {
      self.nextPrevButton();
    }, 500);
  }

  changeHide(currentItem) {
    if (this.HideCompleted && this.currentDomain.progress < 100) {
      this.currentDomain.subdomain1 = this.currentDomain.subdomain.filter(
        s => s.progress < 100
      );
    } else {
      this.currentDomain.subdomain1 = this.currentDomain.subdomain;
    }

    this.currentDomain.subdomain1.forEach((sd_element, sd_index, sd_arr) => {
      setTimeout(() => {
        $('#SubDomainCard' + sd_index).circleProgress({
          value: sd_element.progress / 100,
          size: 50.0,
          emptyFill: '#ccc',
          fill: { gradient: ['#6e61aa', '#209cb7'] }
        }).on('circle-animation-progress', function (event) {
          $(this).find('strong').html('<span>' + sd_element.progress + '%</span>');
        });
        $('#SubDomainCardMobile' + sd_index).circleProgress({
          value: sd_element.progress / 100,
          size: 50.0,
          emptyFill: '#ccc',
          fill: { gradient: ['#6e61aa', '#209cb7'] }
        }).on('circle-animation-progress', function (event) {
          $(this).find('strong').html('<span>' + sd_element.progress + '%</span>');
        });
        $('#SubDomain' + sd_index).circleProgress({
          value: sd_element.progress / 100,
          size: 50.0,
          emptyFill: '#ccc',
          fill: { gradient: ['#6e61aa', '#209cb7'] }
        }).on('circle-animation-progress', function (event) {
          $(this).find('strong').html('<span>' + sd_element.progress + '%</span>');
        });
      }, 200);
    });

    $('.owl.carousel').trigger('destroy.owl.carousel');
    $('#main-block').data('owlCarousel')?.destroy();
    $('#thumbs-block').data('owlCarousel')?.destroy();


    this.loadSlider(currentItem);
  }

  setAnswer(question, subdomain, sd_index) {

    this.checkProgress();

    if (subdomain.attempt_question == 1) {
      let update_start_time = { 'assessment_id': this.assessmentDetails.id, 'domain_id': question.domain_id, 'subdomain_id': question.subdomain_id };
      this.AssessmentService.startDomain(update_start_time)
        .then((data) => {
          subdomain.start_time = data['data']['started_time'];
          if (this.currentDomain.attempt_question == 1) {
            this.currentDomain.start_time = subdomain.start_time;
          }
        },
          (error) => {
            this.globals.isLoading = false;
          });
    }

    let answer = { 'id': question.id, 'rating': question.rating, 'question_text': question.question_text, 'is_mark_as_review': 0 };
    this.AssessmentService.setAnswer(this.assessmentDetails.id, answer)
      .then((data) => {
        subdomain.updated_at = data['data']['updated_at'];
        this.currentDomain.updated_at = subdomain.updated_at;
      },
        (error) => {
          this.globals.isLoading = false;
        });

    var d_element = this.currentDomain;
    var sd_element = this.currentDomain.subdomain1[sd_index];
    if (this.HideCompleted && this.currentDomain.progress == 100) {
      this.currentDomain.subdomain1 = this.currentDomain.subdomain;
      $('.owl.carousel').trigger('destroy.owl.carousel');
      $('#main-block').data('owlCarousel').destroy();
      $('#thumbs-block').data('owlCarousel').destroy();
      this.currentDomain.subdomain1.forEach((sd_element, sd_index, sd_arr) => {
        setTimeout(() => {
          $('#SubDomainCard' + sd_index).circleProgress({
            value: sd_element.progress / 100,
            size: 50.0,
            emptyFill: '#ccc',
            fill: { gradient: ['#6e61aa', '#209cb7'] }
          }).on('circle-animation-progress', function (event) {
            $(this).find('strong').html('<span>' + sd_element.progress + '%</span>');
          });
          $('#SubDomainCardMobile' + sd_index).circleProgress({
            value: sd_element.progress / 100,
            size: 50.0,
            emptyFill: '#ccc',
            fill: { gradient: ['#6e61aa', '#209cb7'] }
          }).on('circle-animation-progress', function (event) {
            $(this).find('strong').html('<span>' + sd_element.progress + '%</span>');
          });
          $('#SubDomain' + sd_index).circleProgress({
            value: sd_element.progress / 100,
            size: 50.0,
            emptyFill: '#ccc',
            fill: { gradient: ['#6e61aa', '#209cb7'] }
          }).on('circle-animation-progress', function (event) {
            $(this).find('strong').html('<span>' + sd_element.progress + '%</span>');
          });
        }, 200);
      });
      this.loadSlider(sd_index);
    }

    setTimeout(() => {
      $('#current_domain_circle').circleProgress({
        value: d_element.progress / 100,
        size: 50.0,
        emptyFill: '#ccc',
        fill: { gradient: ['#6e61aa', '#209cb7'] }
      }).on('circle-animation-progress', function (event) {
        $(this).find('strong').html('<span>' + d_element.progress + '%</span>');
      });
      $('#SubDomainCard' + sd_index).circleProgress({
        value: sd_element.progress / 100,
        size: 50.0,
        emptyFill: '#ccc',
        fill: { gradient: ['#6e61aa', '#209cb7'] }
      }).on('circle-animation-progress', function (event) {
        $(this).find('strong').html('<span>' + sd_element.progress + '%</span>');
      });
      $('#SubDomainCardMobile' + sd_index).circleProgress({
        value: sd_element.progress / 100,
        size: 50.0,
        emptyFill: '#ccc',
        fill: { gradient: ['#6e61aa', '#209cb7'] }
      }).on('circle-animation-progress', function (event) {
        $(this).find('strong').html('<span>' + sd_element.progress + '%</span>');
      });
      $('#SubDomain' + sd_index).circleProgress({
        value: sd_element.progress / 100,
        size: 50.0,
        emptyFill: '#ccc',
        fill: { gradient: ['#6e61aa', '#209cb7'] }
      }).on('circle-animation-progress', function (event) {
        $(this).find('strong').html('<span>' + sd_element.progress + '%</span>');
      });
    }, 200);
    var height_div = $("#main-block .owl-item.synced").height();
    $("#main-block .owl-wrapper-outer").css("height", height_div);

    // $('.owl.carousel').trigger('destroy.owl.carousel');
    // $('#main-block').data('owlCarousel').destroy();
    // $('#thumbs-block').data('owlCarousel').destroy();
    // this.loadSlider(0);


    if (this.assessmentDetails.valid) {
      var popupShown = localStorage.getItem("isPopupShown");
      var dataObject = popupShown ? JSON.parse(popupShown) : null;

      if (!this.isEditAssesment && (!dataObject || dataObject.id !== this.assessmentDetails.id || dataObject.isPopupShown !== "true")) {
        Swal.fire({
          icon: 'success',
          title: "All Knowledge Statements Attempted",
          text: "You've successfully completed all knowledge statements.",
          confirmButtonText: 'Back to subscriptions',
        }).then((result) => {
          if (result.isConfirmed) {
            this.backToSubscription();
          }
        });

        var data = {
          id: this.assessmentDetails.id,
          isPopupShown: "true"
        };
        localStorage.setItem("isPopupShown", JSON.stringify(data));
      }
    }


  }

  submitConfirm() {
    this.globals.isLoading = true;
    this.AssessmentService.getDefaultQuestions(this.assessmentDetails.id)
      .then((data) => {
        this.SurveyQuestions = data['data'];
        if (this.SurveyQuestions[0].question_text == null || this.SurveyQuestions[0].question_text == '') {
          this.SurveyQuestions[0].question_text = 'Please indicate your primary practice area.';
          this.SurveyQuestions[0].answer_text = [];
        } else {
          this.SurveyQuestions[0].answer_text = (this.SurveyQuestions[0].answer_text) ? this.SurveyQuestions[0].answer_text.split(',') : [];
        }
        if (this.SurveyQuestions[1].question_text == null || this.SurveyQuestions[1].question_text == '') {
          this.SurveyQuestions[1].question_text = 'Please provide feedback on the competencies. We are particularly interested if competencies are missing.';
        }
        if (this.SurveyQuestions[2].question_text == null || this.SurveyQuestions[2].question_text == '') {
          this.SurveyQuestions[2].question_text = 'Please indicate how well the competencies were adequately covered?';
        }
        this.globals.isLoading = false;
        $('#QuestionModal').modal('toggle');
      },
        (error) => {
          this.globals.isLoading = false;
        });
  }

  submitFinal() {
    $('#QuestionModal').modal('toggle');
    this.globals.isLoading = true;
    this.AssessmentService.finalSubmit(this.assessmentDetails.id)
      .then((data) => {
        this.globals.isLoading = false;
        //this.globals.sweetAlert('success','Assessment Submitted','Assessment has been submitted successfully.');
        this.router.navigate(['/assessment/result/1/' + this.assessmentDetails.id]);
      },
        (error) => {
          this.globals.isLoading = false;
        });
  }

  saveDefaultQuestion(index) {
    var defaultQuestion = this.SurveyQuestions[index];
    var answer = (defaultQuestion.answer_text) ? ((defaultQuestion.answer_text.constructor === Array) ? ((defaultQuestion.answer_text.join() == '') ? null : defaultQuestion.answer_text.join()) : defaultQuestion.answer_text) : null;
    let entity = { 'assessment_id': this.assessmentDetails.id, "question_id": defaultQuestion.question_id, "question_text": defaultQuestion.question_text, "answer_text": answer };
    this.AssessmentService.submitDefaultQuestion(entity)
      .then((data) => {
      },
        (error) => {
          this.globals.isLoading = false;
        });
  }

  checkProgress() {
    this.assessmentDetails.valid = true;
    this.assessmentDetails.no_of_question = 0;
    this.assessmentDetails.attempt_question = 0;
    this.assessmentDetails.progress = 0;
    this.assessmentData?.forEach((d_element, d_index, d_arr) => {
      d_element.subdomain?.forEach((sd_element, sd_index, sd_arr) => {
        sd_element.questions?.forEach((q_element, q_index, q_arr) => {
          if (sd_index == 0 && q_index == 0) {
            d_element.no_of_question = 0;
            d_element.attempt_question = 0;
          }
          if (q_index == 0) {
            sd_element.no_of_question = 0;
            sd_element.attempt_question = 0;
          }
          this.assessmentDetails.no_of_question++;
          d_element.no_of_question++;
          sd_element.no_of_question++;
          if (q_element.rating != null && q_element.rating != '' && q_element.rating != undefined) {
            this.assessmentDetails.attempt_question++;
            d_element.attempt_question++;
            sd_element.attempt_question++;
          }
          d_element.progress = Math.round((d_element.no_of_question > 0) ? (d_element.attempt_question * 100) / d_element.no_of_question : 0);
          sd_element.progress = Math.round((sd_element.no_of_question > 0) ? (sd_element.attempt_question * 100) / sd_element.no_of_question : 0);
          sd_element.progress_of_required = Math.round((sd_element.mandatory_question > 0) ? (sd_element.attempt_question * 100) / sd_element.mandatory_question : 100);
          this.assessmentDetails.progress = Math.round((this.assessmentDetails.no_of_question > 0) ? (this.assessmentDetails.attempt_question * 100) / this.assessmentDetails.no_of_question : 0);
          if (sd_element.progress_of_required < 100 && ((q_index + 1) == q_arr.length)) {
            this.assessmentDetails.valid = false;
          }
        });
      });
    });
    for (let i = 0; i < this.assessmentData.length; i++) {
      setTimeout(() => {
        var progress = this.assessmentData[i].progress;
        $('#domain_circle' + i).circleProgress({
          value: progress / 100,
          size: 50.0,
          emptyFill: '#ccc',
          fill: { gradient: ['#6e61aa', '#209cb7'] }
        }).on('circle-animation-progress', function (event) {
          $(this).find('strong').html('<span>' + progress + '%</span>');
        });
        $('#domain_circle_' + i).circleProgress({
          value: progress / 100,
          size: 50.0,
          emptyFill: '#ccc',
          fill: { gradient: ['#6e61aa', '#209cb7'] }
        }).on('circle-animation-progress', function (event) {
          $(this).find('strong').html('<span>' + progress + '%</span>');
        });
      }, 100);
    }
  }

  loadSlider(index) {
    let self = this;
    setTimeout(function () {
      var sync1 = $("#main-block");
      var sync2 = $("#thumbs-block");
      sync1.owlCarousel({
        singleItem: true,
        responsive: true,
        autoPlay: false,
        slideSpeed: 1000,
        navigation: true,
        navText: [
          "<button class='my-prev-class'>Previous to Subdomain</button>",  // Custom class for previous button
          "<button class='my-next-class'>Next to Subdomain</button>"   // Custom class for next button
        ],
        // navigationText: ["Previous Subdomain", "Next Subdomain"],
        loop: false,
        rewindNav: false,
        mouseDrag: false,
        touchDrag: false,
        pagination: false,
        autoHeight: true,
        afterAction: syncPosition,
        responsiveRefreshRate: 200,
      });
      sync2.owlCarousel({
        items: 4,
        itemsDesktop: [1499, 3],
        itemsDesktopSmall: [979, 2],
        itemsTablet: [768, 1],
        itemsMobile: [479, 1],
        pagination: false,
        responsive: true,
        loop: false,
        autoHeight: true,
        rewindNav: false,
        navigation: true,
        mouseDrag: false,
        touchDrag: false,
        navigationText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
        responsiveRefreshRate: 100,
        afterInit: function (el) {
          el.find(".owl-item").eq(index).addClass("synced");
        }
      });
      function syncPosition(el) {
        var current = this.currentItem;
        $("#thumbs-block")
          .find(".owl-item")
          .removeClass("synced")
          .eq(current)
          .addClass("synced")
        if ($("#thumbs-block").data("owlCarousel") !== undefined) {
          center(current)
        }
        $("#main-block")
          .find(".owl-item")
          .removeClass("synced")
          .eq(current)
          .addClass("synced");
        var height_div = $("#main-block .owl-item.synced").height();
        $("#main-block .owl-wrapper-outer").css("height", height_div);
      }
      $("#thumbs-block").on("click", ".owl-item", function (e) {
        e.preventDefault();
        var number = $(this).data("owlItem");
        sync1.trigger("owl.goTo", number);
        self.nextPrevButton();
      });

      function center(number) {
        var sync2visible = sync2.data("owlCarousel").owl.visibleItems;
        var num = number;
        var found = false;
        for (var i in sync2visible) {
          if (num === sync2visible[i]) {
            var found = true;
          }
        }
        if (found === false) {
          if (num > sync2visible[sync2visible.length - 1]) {
            sync2.trigger("owl.goTo", num - sync2visible.length + 2)
          } else {
            if (num - 1 === -1) {
              num = 0;
            }
            sync2.trigger("owl.goTo", num);
          }
        } else if (num === sync2visible[sync2visible.length - 1]) {
          sync2.trigger("owl.goTo", sync2visible[1])
        } else if (num === sync2visible[0]) {
          sync2.trigger("owl.goTo", num - 1)
        }
      }

      $('#thumbs-block .owl-next').click(function () {
      
        $("#main-block").trigger('owl.next');
        var height_div = $("#main-block .owl-item.synced").height();
        $("#main-block .owl-wrapper-outer").css("height", height_div);
        self.nextPrevButton();
        window.scrollTo({ top: 0, behavior: 'smooth' });
      });
      $('#thumbs-block .owl-prev').click(function () {
        $("#main-block").trigger('owl.prev');
        var height_div = $("#main-block .owl-item.synced").height();
        $("#main-block .owl-wrapper-outer").css("height", height_div);
        self.nextPrevButton();
        window.scrollTo({ top: 0, behavior: 'smooth' });
      });

      $('.owl-next').click(function () {
        var height_div = $("#main-block .owl-item.synced").height();
        $("#main-block .owl-wrapper-outer").css("height", height_div);
        self.nextPrevButton();
        window.scrollTo({ top: 0, behavior: 'smooth' });
      });
      $('.owl-prev').click(function () {
        var height_div = $("#main-block .owl-item.synced").height();
        $("#main-block .owl-wrapper-outer").css("height", height_div);
        self.nextPrevButton();
        window.scrollTo({ top: 0, behavior: 'smooth' });
      });

      var height_div = $("#main-block .owl-item.synced").height();
      $("#main-block .owl-wrapper-outer").css("height", height_div);

    }, 1000);

    setTimeout(() => {
      self.nextPrevButton();
    }, 100);
    setTimeout(() => {
      var sync1 = $("#main-block");
      var current = index;
      $("#thumbs-block")
        .find(".owl-item")
        .removeClass("synced")
        .eq(current)
        .addClass("synced");
      sync1.trigger("owl.goTo", index);
      this.globals.isLoading = false;
    }, 1000);
  }

  nextPrevButton() {
    $('#prev_btn').hide();
    $('#next_btn').hide();
    if ($('.synced .item').hasClass('firstdomain_last')) {
      $('#next_btn').show();
    } else {
      $('#next_btn').hide();
    }
    if ($('.synced .item').hasClass('seconddomain_first')) {
      $('#prev_btn').show();
    } else {
      $('#prev_btn').hide();
    }

  

   

  }

  getNextDomainButton(){
    if ($('.synced .item').hasClass('firstdomain_last') && (this.domain_index - (this.assessmentData.length - 1) != 0) ){
      
     return true;
    } else {
      return false;
    }
  }

  getPreviousDomainButton() {
    if (this.domain_index == 0) {
      return false;
    }else{
      
    }

    if ($('.synced .item').hasClass('seconddomain_first') && (this.domain_index + (this.assessmentData.length + 1) != 0)) {
      return true;
    } else {
      return false;
    }
  }

  sortRating(a, b) {
    const valueA = a.value.toUpperCase();
    const valueB = b.value.toUpperCase();
    let comparison = 0;
    if (valueA > valueB) {
      comparison = 1;
    } else if (valueA < valueB) {
      comparison = -1;
    }
    return comparison;
  }

  sortDomain(a, b) {
    const valueA = a.name.toUpperCase();
    const valueB = b.name.toUpperCase();
    let comparison = 0;
    if (valueA > valueB) {
      comparison = 1;
    } else if (valueA < valueB) {
      comparison = -1;
    }
    return comparison;
  }

  sortSubDomain(a, b) {
    const valueA = a[0].toUpperCase();
    const valueB = b[0].toUpperCase();
    let comparison = 0;
    if (valueA > valueB) {
      comparison = 1;
    } else if (valueA < valueB) {
      comparison = -1;
    }
    return comparison;
  }

  public isSurveyQuestionOptionsSelected(i) {
    return this.SurveyQuestions[0].answer_text.some((item) => item === i);
  }

  viewChange(data) {
    if (data == true) {
      if (this.IsGridView) {
        this.IsGridView = false;
      }
    } else {
      if (!this.IsGridView) {
        this.IsGridView = true;
      }
    }
  }

  getSubdomainName(subdomain: string) {
    return subdomain.slice(0, 60) + (subdomain.length > 60 ? '...' : '');
  }


}
